<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        Invite Users <small>(Step 10 of 10)</small>
      </h3>
      <h4 class="pb-5">
        Invite users to collaborate with you on Pilot<br />
        To invite your colleagues kindly enter their emails in the email
        field<br />
        (you can add multiple emails at a time)
      </h4>
      <div class="row">
        <form
          @submit.prevent="submit"
          class="form col-md-8 mx-auto mt-5"
          autocomplete="false"
        >
          <div class="pb-5">
            <div class="form-group">
              <label
                >Administrators<i
                  class="pl-3 text-primary fas fa-question-circle"
                  id="adminCallout"
                ></i
              ></label>

              <b-popover target="adminCallout" triggers="hover" placement="top">
                <template #title>Administrator</template>
                Adminstrators can create/edit users, create/edit/delete
                settings, manage organizational info, run analytics
              </b-popover>
              <v-combobox
                v-model="admins"
                deletable-chips
                chips
                clearable
                filled
                multiple
                small-chips
                solo
                autocomplete="false"
                persistent-hint
                hint="Invite multiple administrators by entering an email then hit enter
                      to add more"
                label="start typing to enter emails"
              >
              </v-combobox>
              <template v-if="$v.admins.$each.$error">
                <span class="form-text text-danger">
                  One of the administrators email is not properly formed please
                  delete and re add
                </span>
              </template>
            </div>
            <div class="form-group">
              <label
                >Editors<i
                  class="pl-3 text-primary fas fa-question-circle"
                  id="editorCallout"
                ></i
              ></label>
              <b-popover
                target="editorCallout"
                triggers="hover"
                placement="top"
              >
                <template #title>Editors</template>
                Editors can make and edit entries, view and spool reports
              </b-popover>
              <v-combobox
                v-model="editors"
                deletable-chips
                chips
                clearable
                filled
                multiple
                small-chips
                solo
                persistent-hint
                hint="Invite multiple editors by entering an email then hit enter
                      to add more"
                label="start typing to enter emails"
              >
              </v-combobox>
              <template v-if="$v.editors.$each.$error">
                <span class="form-text text-danger">
                  One of the editors email is not properly formed please delete
                  and re add
                </span>
              </template>
            </div>
            <div class="form-group">
              <label
                >Contributors<i
                  class="pl-3 text-primary fas fa-question-circle"
                  id="contributorsCallout"
                ></i
              ></label>
              <b-popover
                target="contributorsCallout"
                triggers="hover"
                placement="top"
              >
                <template #title>Contributors</template>
                Contributors can make entries only and view and spool reports
              </b-popover>
              <v-combobox
                v-model="contributors"
                deletable-chips
                chips
                clearable
                filled
                multiple
                small-chips
                solo
                persistent-hint
                hint="Invite multiple contributors by entering an email then hit enter
                      to add more"
                label="start typing to enter emails"
              >
              </v-combobox>
              <template v-if="$v.contributors.$each.$error">
                <span class="form-text text-danger">
                  One of the contributors email is not properly formed please
                  delete and re add
                </span>
              </template>
            </div>
          </div>

          <div class="pb-lg-0 pb-5 float-right mt-5">
            <router-link
              v-if="currentUser.company.previous_onboarding_stage == 11"
              to="/onboarding/action-parties"
              v-slot="{ navigate }"
            >
              <button
                type="button"
                class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                @click="navigate"
              >
                <i class="fas fa-arrow-left ml-2"></i>
                Back
              </button>
            </router-link>
            <router-link
              v-if="currentUser.company.previous_onboarding_stage == 10"
              to="/onboarding/choose-action-party"
              v-slot="{ navigate }"
            >
              <button
                type="button"
                class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                @click="navigate"
              >
                <i class="fas fa-arrow-left ml-2"></i>
                Back
              </button>
            </router-link>

            <button
              type="submit"
              class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
            >
              <b-spinner
                v-if="loading == true"
                variant="light"
                label="Spinning"
              ></b-spinner>
              <span v-if="loading == false">Finish</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
//import Swal from "sweetalert2";
//import Multiselect from "vue-multiselect";
import CompanyService from "@/core/services/company.service.js";
import SignupService from "@/core/services/signup.service.js";
import { mapGetters, mapState } from "vuex";
import { email } from "vuelidate/lib/validators";
import { UPDATE_AUTH } from "@/core/services/store/auth.module";
export default {
  data() {
    return {
      duplicate: false,
      show: true,
      admins: [],
      editors: [],
      contributors: [],
      loading: false
    };
  },
  validations: {
    admins: {
      $each: {
        email
      }
    },
    editors: {
      $each: {
        email
      }
    },
    contributors: {
      $each: {
        email
      }
    }
  },
  components: {
    //Multiselect
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {},

  methods: {
    submit() {
      let intxni = this.admins.filter(x => this.editors.includes(x));
      let intxnii = this.admins.filter(x => this.contributors.includes(x));
      let intxniii = this.editors.filter(x => this.contributors.includes(x));

      if (intxni.length > 0 || intxnii.length > 0 || intxniii.length > 0) {
        this.makeToast(
          "danger",
          "Validation Error",
          "You have entered an email address more than once. You cannot invite one email address to multiple roles"
        );
        return;
      }

      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.admins.length > 0 ||
          this.editors.length > 0 ||
          this.contributors.length > 0
        ) {
          this.loading = true;
          SignupService.sendMultipleInvite(
            this.admins,
            this.editors,
            this.contributors
          )
            .then(response => {
              console.log(response);
              this.nextStep();
            })
            .catch(error => {
              if (error.status == 422) {
                let errors = Object.values(error.data.errors);
                errors = errors.flat();
                this.makeToast("danger", "Validation Error", errors[0]);
              } else if (error.status == 400) {
                this.makeToast("danger", error.statusText, error.data.message);
              } else {
                this.makeToast(
                  "danger",
                  "Oops",
                  "An unexpected exception has occured. Could not populate company sectors at the moment, please give it a minute and refresh page"
                );
              }
              this.loading = false;
              console.log(error);
            });
        } else {
          this.nextStep();
        }
      }
    },

    nextStep() {
      var data = {
        previous_onboarding_stage: 10,
        onboarding_stage: 0,
        onboarding_complete: true
      };
      this.loading = true;
      CompanyService.setOnboardingStage(data)
        .then(response => {
          try {
            this.$store.dispatch(UPDATE_AUTH, response.data);
            this.$router.push({ name: "onboarding-complete" });
          } catch (error) {
            console.log(error);
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
            this.loading = false;
          }
        })
        .catch(error => {
          if (error.data) {
            this.makeToast("danger", error.statusText, error.data.message);
          } else {
            this.makeToast(
              "danger",
              "Oops",
              "An unexpected exception has occured. Please give it a minute and refresh page"
            );
          }
          this.loading = false;
        });
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  }
};
</script>
